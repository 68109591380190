@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*,
body {
  scroll-behavior: smooth;
  font-family: "Lato", sans-serif;
}

.bg-image-class {
  background-image: url("./assets/images/backgroundImg.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-10-text-shadow {
  text-shadow: 0 0 3px #000000a7, 0 0 5px #ffffff;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: 20px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 100px;
}

.swiper-slide img {
  display: block;
  width: 85%;
  height: 70vh;
  border-radius: 20px;
}

.top-video-container {
  background: url(./assets/images/TopVid.png);
  background-size: cover;
}

.nav-hover-bg {
  z-index: 100;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.0313375350140056) 0%,
    rgba(0, 0, 0, 0.22461484593837533) 52%,
    rgba(0, 0, 0, 0) 100%
  );
}

.support-us-bg {
  background-image: url(./assets/images/need-prayer-bg.png);
  background-size: cover;
}

.need-prayer-bg {
  background-image: url(./assets/images/support-bg.png);
  background-size: cover;
}

.nav-container:hover .nav-titles {
  font-weight: bold;
  opacity: 1;
}

.img-box {
  background-image: url(./assets/images/test/Test1.png);
  transition: aspect-ratio ease-in-out 0.5s;
  cursor: pointer;
}

.img-box .img-play {
  opacity: 0;
  cursor: pointer;
}

.img-box:hover .img-play {
  opacity: 1;
  cursor: pointer;
}

.img-box-2 {
  background-image: url(./assets/images/learn/learn1.png);
  transition: aspect-ratio ease-in-out 0.5s;
  cursor: pointer;
}

.img-box-2 .img-play {
  cursor: pointer;
}

.img-box-2:hover .img-play {
  cursor: pointer;
}

.bg-image-class-content {
  background-image: url("./assets/images/backgroundImg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-cover-gradient {
  background: rgb(25, 25, 25);
  background: linear-gradient(0deg, rgba(25, 25, 25, 1) 0%, rgba(211, 179, 183, 0) 100%);
}

.story-background {
  background-image: url("./assets/images/backgroundStory.jpg");
  object-fit: cover;
}

.react-tel-input {
  font-size: 1rem !important;
  background: #191919 !important;
  border: none !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 2.5rem !important;
  position: relative;
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
  background: #191919 !important;
}

.player-controls {
  background: rgb(25, 25, 25);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(25, 25, 25, 0) 100%);
}

.player-title {
  background: rgb(25, 25, 25);
  background: linear-gradient(0deg, rgba(25, 25, 25, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.bg-main-carousel-gradient {
  background: rgb(0, 26, 82);
  background: linear-gradient(
    94deg,
    rgba(0, 26, 82, 1) 0%,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(237, 9, 9, 0) 100%
  );
}

.btn-slide {
  animation-duration: 0.4s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 5%;
    opacity: 0;
  }

  to {
    margin-left: 0%;
    opacity: 1;
  }
}

.btn-slide2 {
  animation-duration: 0.4s;
  animation-name: slideTop;
}

@keyframes slideTop {
  from {
    margin-bottom: 5%;
    opacity: 0;
  }

  to {
    margin-bottom: 0%;
    opacity: 1;
  }
}

.btn-slide3 {
  animation-duration: 0.4s;
  animation-name: slideTop2;
}

@keyframes slideTop2 {
  from {
    margin-bottom: 10%;
    opacity: 0;
  }

  to {
    margin-bottom: 0%;
    opacity: 1;
  }
}

.image-animation {
  animation-duration: 0.6s;
  animation-name: slidein2;
}

@keyframes slidein2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.circular-gradient {
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(0, 0, 0, 0.16859243697478987) 0%, rgba(0, 0, 0, 1) 100%);
}

#image-track {
  display: flex;
  gap: 2vmin;
  justify-content: center;
  align-items: center;
}

#image-track > .frame {
  width: 20vmin;
  height: 80vmin;
  object-fit: cover;
  object-position: 50% 50%;
  transition: all 1s;
  translate: (-50%, -50%);
  filter: brightness(45%);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: grid;
  grid-template-rows: auto 20%;
  overflow: hidden;
  border-radius: 5px;
}
#image-track > .frame:hover {
  width: 55vmin;
  height: 80vmin;
  filter: brightness(100%);
}

#image-track > .frame > .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

#image-track > .frame > .header {
  font-family: "Lato", sans-serif;
  font-size: 2.5vmin;
  position: relative;
  left: 10vmin;
  top: 2vmin;
  transition: all 1s;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  color: #ffffff00;
  text-wrap: nowrap;
}
#image-track > .frame:hover > .header {
  left: 2vmin;
  color: #ffffff;
  text-shadow: 0 0 1rem #000, 0 0 1rem #000, 0 0 1rem #000;
}

#image-track > .frame > .textbox {
  background-color: #14141400;
  transition: all 1s;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  width: 90%;
  height: fit-content;
  padding-top: 2vmin;
  padding-bottom: 2vmin;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  box-sizing: border-box;
  max-height: 90%;
  justify-self: center;
  align-self: end;
}
#image-track > .frame:hover > .textbox {
  background-color: #f8dd0e73;
}

#image-track > .frame > .textbox > .subheader {
  font-family: "Lato", sans-serif;
  font-size: 1.5vmin;
  position: relative;
  left: 10vmin;
  transition: all 1s;
  color: #ffffff00;
  text-wrap: nowrap;
  align-self: end;
}
#image-track > .frame:hover > .textbox > .subheader {
  left: 2vmin;
  color: #ffffff;
}

.carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.carousel-item {
  padding: 10px;
  text-align: center;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.owl_wrapper {
  width: 100vw;
  overflow: hidden;
}
.owl_content {
  padding-left: 12%;
}
.owl_wrapper .owl-item {
  opacity: 0;
  transform: scale(0.6);
  -ms-transform: scale(0.6);
  transition: all 0.2s;
  -webkit-transform: all 0.2s;
  z-index: 1;
}
.owl_wrapper .active {
  opacity: 0.5;
}
.owl_wrapper .active.middle_beside {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  opacity: 0.8;
  margin: 0 -1%;
  z-index: 2;
}
.owl_wrapper .active.middle {
  transform: scale(1);
  -ms-transform: scale(1);
  opacity: 1;
  z-index: 3;
}
.owl_wrapper .active.next_to_mid {
  margin-left: -1%;
}
.owl_wrapper .active.prev_to_mid {
  margin-right: -1%;
}

/*===== Navigation =====*/
.owl_wrapper .prev,
.owl_wrapper .next {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background: #777;
  cursor: pointer;
  z-index: 5;
}
.owl_wrapper .next {
  left: calc(100% - 100px);
}
.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.upload-btn-wrapper {
  position: relative;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  right: 0;
  top: 0;
  opacity: 0;
}

.react-tel-input {
  font-size: 1rem !important;
  background: #191919 !important;
  border: none !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 2.5rem !important;
  position: relative;
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
  background: #191919 !important;
}

.RichTextEditor__root___2QXK- {
  border: none !important;
  border-radius: 8px !important;
  color: #868686 !important;
  min-height: 200px !important;
  border: solid 1px rgb(202, 202, 202) !important;
}

.react-datetime-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  border: none !important;
  background: #191919 !important;
  border-radius: 5px !important;
  height: 40px !important;
  padding: 4px !important;
  width: 100% !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.react-datetime-picker__calendar {
  z-index: 100 !important;
}
.react-datetime-picker__calendar .react-calendar {
  border-width: thin !important;
  background: #191919 !important;
  border-radius: 10px !important;
  color: #fff;
}

.react-calendar__navigation__label:focus,
.react-calendar__navigation__arrow:hover,
.react-calendar__tile:hover,
.react-calendar__navigation__label:hover {
  color: #000;
}

.react-calendar__tile--now {
  background: #0d9604 !important;
  border-radius: 5px !important;
}

.swiper-pagination-bullet {
  height: 6px !important;
  width: 6px !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  background: #7d7d7d94 !important;
}

.swiper-pagination-bullet-active {
  width: 80px !important;
  background: #7d7d7d94 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.651) !important;
  border-radius: 6px !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  margin-right: 0.3%;
  margin-left: 0.3%;
}

.carousel.carousel-slider .control-arrow {
  padding: 1.5% !important;
  padding-top: 2% !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 1% solid #efbc51 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 1% solid #efbc51 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scrollbarV1::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Width of the scrollbar track */
}

.custom-scrollbarV1::-webkit-scrollbar-thumb {
  background-color: #191919; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
  border-left: 2px solid #6e6e6e;
  border-right: 2px solid #6e6e6e;
}

.custom-scrollbarV1::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Color when hovering over the thumb */
}

.custom-scrollbarV1::-webkit-scrollbar-track {
  background-color: #6e6e6e; /* Color of the scrollbar track */
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23EFBC51'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23EFBC51'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: none !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 15px solid transparent !important;
  border-bottom: 15px solid transparent !important;
}

.custom-content-description ol {
  list-style: decimal;
  margin-left: 1rem;
}

.custom-content-description ul {
  list-style: disc;
  margin-left: 1rem;
}

/* Custom CSS for hamburger icon animation */
.hamburger-line {
  width: 25px;
  height: 2px;
  background-color: white;
  margin: 5px 0;
  transition: all 0.3s;
}
.hamburger-icon.open .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}
.hamburger-icon.open .hamburger-line:nth-child(2) {
  opacity: 0;
}
.hamburger-icon.open .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -5px);
}

/* CSS to hide and translate the mobile menu */
.mobile-menu {
  transform: translateX(-100%);
  transition: transform 0.3s;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
}
.mobile-menu.open {
  transform: translateX(0);
}
.mobile-menu ul {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  list-style: none;
  margin: 0;
}
.mobile-menu ul li {
  margin-bottom: 1rem;
}
.mobile-menu ul li a {
  color: #fff;
  font-weight: bold;
}

.home-maincarousel-linear-gradient {
  background: linear-gradient(
      180deg,
      rgba(15, 16, 20, 0) 73.08%,
      rgba(15, 16, 20, 0.01) 77.84%,
      rgba(15, 16, 20, 0.05) 79.74%,
      #000
    ),
    linear-gradient(
      90deg,
      #000,
      rgba(15, 16, 20, 0.9) 7.31%,
      rgba(15, 16, 20, 0.52) 15.54%,
      rgba(15, 16, 20, 0.12) 29.73%,
      rgba(15, 16, 20, 0) 40.59%
    );
}

.custom-swiper .swiper-slide-next {
  margin-top: -1.7rem; /* Adjust the margin to control visibility of the next slide */
}

.custom-drop-shadowV1 {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.ReactModal__Overlay {
  z-index: 1000;
}

@keyframes smoothGrow {
  0% {
    transform: scale(0.8); /* start from 75% */
  }
  100% {
    transform: scale(1.1); /* grow to 110% */
  }
}

.animate-grow {
  animation: smoothGrow 0.6s ease forwards; /* play animation for 1 second */
}

.custom-player video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.custom-content-description p {
  margin-bottom: 1rem;
}

.custom_privacy_policy h1 {
  @apply text-white text-3xl font-semibold py-5 text-center;
}

.custom_privacy_policy h2 {
  @apply font-semibold text-white text-lg;
}

.custom_privacy_policy p {
  @apply mb-4;
}

.custom_privacy_policy ul {
  @apply pl-5 list-disc;
}

.custom_privacy_policy a {
  @apply text-yellowish underline;
}

.custom_privacy_policy span {
  color: inherit !important;
  background-color: inherit !important;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #2e2e2e;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #111;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #111;
}

.custom-player-v2 video {
  object-fit: contain;
  object-position: top;
}

.custom-player-v3 video {
  object-fit: cover;
  object-position: top;
}

video::media-control {
  z-index: 10000; /* Doesn't always work consistently */
}

.download-button-shadow {
  box-shadow: #1c1c1c 0px 54px 55px, #1c1c1c 0px -12px 30px, #1c1c1c 0px 4px 6px,
    #1c1c1c 0px 12px 13px, #1c1c1c 0px -3px 5px;
}

@media screen and (max-width: 480px) {
  .custom-content-description * {
    font-size: 16px !important;
  }
}

.enable-list-style ul {
  list-style: disc;
  margin: auto;
  padding-left: 1rem;
}

.enable-list-style ol {
  list-style: decimal;
  margin: auto;
  padding-left: 1rem;
}

.grid-cols-20 {
  grid-template-columns: repeat(20, minmax(0, 1fr));
}

/* Style the range track */
.custom-range {
  -webkit-appearance: none; /* Removes default styling */
  width: 65%;
  height: 2px;
  background: linear-gradient(to right, #efbc51 50%, gray 50%); /* Track color */
  outline: none;
  border-radius: 5px;
}

/* Customizing the thumb (slider knob) */
.custom-range::-webkit-slider-thumb {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 5px;
  background: black; /* Thumb color */
  border-radius: 50%;
  cursor: pointer;
}
.custom-range::-moz-range-progress {
  background: gray; /* Filled portion */
}

.custom-arrow .swiper-button-prev {
  width: 20px !important;
  background-repeat: no-repeat;
}
.custom-arrow .swiper-button-next {
  width: 20px !important;
  background-repeat: no-repeat;
}

.custom-arrowV2 .swiper-button-prev {
  left: 0px !important;
}
.custom-arrowV2 .swiper-button-next {
  right: 0px !important;
}

.z-10000 {
  z-index: 10000 !important;
}
